<template>
  <div class="autosize-textarea">
    <textarea
      ref="textarea"
      class="input"
      name="query"
      :rows="minRows"
      :style="textareaStyle"
      :placeholder="shownPlaceholder"
      v-model="text"
      v-bind="$attrs"
      v-on="$listeners"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
    ></textarea>
    <slot></slot>
    <ai-icon v-if="isIconShown" :size="20" class="ai-icon"></ai-icon>
  </div>
</template>

<script>
import AiIcon from 'vue-material-design-icons/Creation';

export default {
  inheritAttrs: false,
  props: {
    initialText: {type: String, default: ''},
    minRows: {type: Number, default: 4},
    maxRows: {type: Number, default: 8},
    lineHeight: {type: Number, default: 24},
    paddingRight: {type: Number, default: 50},
    placeholder: {type: String, default: 'Ask AI to search our database for you...'},
    showIcon: {type: Boolean, default: false},
  },
  mounted() {
    this.adjustHeight();
  },
  data() {
    return {
      text: this.initialText,
      overflowY: 'hidden',
      paddingTop: 9,
      paddingBottom: 9,
      isFocused: false,
    };
  },
  computed: {
    textareaStyle() {
      return {
        width: '100%',
        resize: 'none',
        'padding-right': this.paddingRight + 'px',
        'padding-left': this.isIconShown ? '40px' : '12px',
        overflowY: this.overflowY,
        position: 'relative',
      };
    },
    isIconShown() {
      return this.showIcon && !this.isFocused && !this.text;
    },
    shownPlaceholder() {
      if (!this.showIcon) {
        return this.placeholder;
      }
      return this.isIconShown ? this.placeholder : '';
    },
  },
  methods: {
    onInput(event) {
      this.adjustHeight();
    },
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
    clearText() {
      this.text = '';
      this.$refs.textarea.blur();
    },
    adjustHeight() {
      this.$nextTick(() => {
        const el = this.$refs.textarea;
        if (!el) return;
        el.style.height = 'auto';
        const paddingHeight = this.paddingTop + this.paddingBottom;
        const minHeight = paddingHeight + this.minRows * this.lineHeight;
        const scrollHeight = el.scrollHeight;
        const maxHeight = paddingHeight + this.maxRows * this.lineHeight;
        el.style.height = `${Math.max(minHeight, Math.min(scrollHeight, maxHeight))}px`;
        this.overflowY = scrollHeight > maxHeight ? 'scroll' : 'hidden';
      });
    },
  },
  components: {AiIcon},
};
</script>
<style scoped lang="scss">
.autosize-textarea {
  .ai-icon {
    color: $primary-600;
    position: absolute;
    left: 12px;
    top: 10px;
  }
}
</style>
