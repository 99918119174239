import {
  TAB_ID_FULL_TEXT,
  TAB_ID_INDEXED_RECORDS,
  TAB_ID_SOURCES,
  VALIDATE_FACT_FIELD_SET,
} from '@common/utils/consts.search';

export function isSourcesFormPlaceFieldDisabled(formData) {
  const ancestralPlaceId = formData.ancestral_place && formData.ancestral_place.id;
  return Boolean(formData.country || ancestralPlaceId);
}

export function getFactsForSearch(formData) {
  return formData.facts.filter(fact => {
    return VALIDATE_FACT_FIELD_SET.some(fieldName => fact[fieldName]);
  });
}

export function getClanName(clan) {
  const nameCh =
    clan.name_hant && clan.name_ch !== clan.name_hant ? `${clan.name_ch} / ${clan.name_hant}` : clan.name_ch;
  return [clan.pinyin || '', nameCh].join(' ').trim() || clan.name;
}

export function isRecordYearValid(year) {
  if (!year || typeof year === 'number') {
    return true;
  }
  if (typeof year === 'string') {
    const onlyDigits = /^\d+$/;
    const cFormat = /^c-\d+$/;
    const dFormat = /^d-\d+$/;
    return onlyDigits.test(year) || cFormat.test(year) || dFormat.test(year);
  }
  return false;
}

export function getRedirectRouteFromAiSearch(response) {
  if (response.search_name === 'person_search') {
    return {
      name: 'search-all-records',
      query: {
        tab: TAB_ID_INDEXED_RECORDS,
        results: true,
        ...response.filters,
      },
    };
  }
  if (response.search_name === 'full_text_search') {
    return {
      name: 'search-all-records',
      query: {
        tab: TAB_ID_FULL_TEXT,
        results: true,
        ...response.filters,
      },
    };
  }
  if (response.search_name === 'source_search') {
    return {
      name: 'search-all-records',
      query: {
        tab: TAB_ID_SOURCES,
        results: true,
        ...response.filters,
      },
    };
  }
  if (response.search_name === 'chinese_places_search') {
    return {name: 'search-tool-village', query: {...response.filters}};
  }
  if (response.search_name === 'world_places_search') {
    return {name: 'search-overseas', query: {...response.filters}};
  }
  if (response.search_name === 'surname_search') {
    return {name: 'search-tool-surname', query: {...response.filters}};
  }
}
